import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { AnnexConfirmation } from "page_components/contact"

const AnnexConfirm = () => {
  const title = "Potwierdzenie"

  return (
    <Layout>
      <Breadcrumbs title={title} />
      <PageHeader title="Aneks do umowy został potwierdzony" />
      <AnnexConfirmation />
    </Layout>
  )
}

export default AnnexConfirm
